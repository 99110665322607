<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="product_hub_stock_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Product Unit</th>
              <th>Current Stock</th>
              <th>Threshold Limit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, index) in products" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.unit }}</td>
              <td>{{ product.hub_product_stock ? product.hub_product_stock.stock_amount : 0 }}</td>
              <td>{{ product.hub_threshold_limit }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="products.length === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      products: {},
      hub_stock: {},
      loading: false,
      flag: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.flag = true
      this.loading = true
      const hubId = this.$route.params.hub_id
      apiClient.get('api/hub/product-stock/search/' + hubId)
        .then(response => {
          this.loading = false
          this.flag = true
          this.products = response.data.products
          setTimeout(function() {
            $('#product_hub_stock_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
